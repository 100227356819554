import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Table, { ColumnsType } from 'antd/es/table'
import clsx from 'clsx'
import dayjs from 'dayjs'
import moment from 'moment'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import SUCCESS from '../../../../../assets/images/success.png'
import CDatePicker from '../../../../../components/Common/CDatePicker'
import CustomPopup from '../../../../../components/Common/CustomPopup/CustomPopup'
import CustomSearch from '../../../../../components/Common/CustomSearch/CustomSearch'
import Documents from '../../../../../components/Common/Documents/Documents'
import Loading from '../../../../../components/Common/Loading/Loading'
import ModalDetailComponent from '../../../../../components/Common/ModalDetails/Detail'
import FormCreation from '../../../../../components/Common/ModalDetails/FormCreation'
import InputRadio from '../../../../../components/Common/ModalDetails/InputRadio'
import Define from '../../../../../constants/define'
import {
  ActionType,
  FormCreationType,
  StatusAPI,
  TableType,
} from '../../../../../enum'
import { EtaAttendu } from '../../../../../enum/EtaAttendu'
import { MODE } from '../../../../../enum/mode'
import { popupType } from '../../../../../enum/popupType'
import { StatusAttendu } from '../../../../../enum/StatusAttendu'
import { focusById } from '../../../../../hook/usePressTab'
import attenduApi from '../../../../../http/attenduApi'
import fileApi from '../../../../../http/fileApi'
import fournisseurApi from '../../../../../http/fournisserApi'
import {
  Adresses,
  AttCommon,
  Attendu,
  Contact,
  FileResponse,
  ReferenceAttendu,
} from '../../../../../models'
import { initialAttendu } from '../../../../../redux/reducers/attenduSlice'
import {
  clearCurrentFournisseur,
  getFournisseurByCode,
  setCurrentFournisseurEdit,
} from '../../../../../redux/reducers/fournisseurSlice'
import {
  getCurrentFiltersText,
  randomID,
  removeTones,
  renderNull,
} from '../../../../../utils'
import { mapDataAttProduction } from '../../../../../utils/attendu/mapDataAttProduction'
import { mapDataDetail } from '../../../../../utils/attendu/mapDataDetail'
import { DataTableParam } from '../../../stockscreen/component/model'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { NameAndValue } from '../../../stockscreen/component/Wrapper'
import { checkIfClientWarehouseCompanyCorrespond } from '../../../stockscreen/func'
import '../../CustomModal.scss'
import { ModalName, ModalType, openModal } from '../../customModalSlice'
import './Attendu.scss'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const AttenduEdit = () => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { name, type } = useSelector((state: RootState) => state.modal)
  const { currentFournisseur, currentFournisseurEdit } = useSelector(
    (state: RootState) => state.fournisseur
  )
  const { metadata } = useSelector(
    (state: RootState) => state.attendu.currentListAttendu
  )

  const [isCreateFournisseur, setIsCreateFournisseur] = useState<boolean>(false)

  const [dataFournisseurAdresses, setDataFournisseurAdresses] = useState<
    Adresses[]
  >([])
  const [dataFournisseurContact, setDataFournisseurContact] = useState<
    Contact[]
  >([])
  const [isExistAttendu, setIsExistAttendu] = useState<string>('')
  const [isExistNoFournisseur, setIsExistNoFournisseur] = useState<string>('')
  const refToTop = useRef<HTMLInputElement>(null)
  const [attFournisseur, setAttFournisseur] = useState<AttCommon>({})
  const [attendu, setAttendu] = useState<Attendu>()
  const [createAt, setCreateAt] = useState<string>('')
  const [updateAt, setUpdateAt] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false)
  const [openPopupDelete, setOpenPopupDelete] = useState<boolean>(false)
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false)
  const [dataFile, setDataFile] = useState<FileResponse[]>([])

  //detail component
  const detail = useSelector((state: RootState) => state.attendu.detail)
  const [detailData, setDetailData] = useState<DataTableParam[][]>([])
  const [detailDataFromApi, setDetailDataFromApi] = useState<
    DataTableParam[][]
  >([])
  const [isValid_detailData, set_isValid_detailData] = useState<boolean>(false)
  const [isShowAlertDetail, setIsShowAlertDetail] = useState<boolean>(false)
  const [isShowAlertDetailMission, setIsShowAlertDetailMission] =
    useState<boolean>(false)

  const [numberOfSubmit, setNumberOfSubmit] = useState<number>(0)

  const [isEmptyCommande, setIsEmptyCommande] = useState<boolean>(false)
  const [isEmptyLivraison, setIsEmptyLivraison] = useState<boolean>(false)
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  const [isValid_detailData_mission, setIsValid_detailData_mission] =
    useState<boolean>(false)
  const [isDetailInit, setIsDetailInit] = useState<boolean>(false)
  const [numberOfPhotos, setNumberOfPhotos] = useState<number>(0)
  const attenduKey = useSelector((state: RootState) => state.selector).data
    .attendu
  const columnsAdresses: ColumnsType<Adresses> = [
    {
      title: <div className="form-label">Adresse</div>,
      dataIndex: 'addresse',
      key: 'addresse',
      width: 370,
      render: (_, row) =>
        `${renderNull(row.addresse as string)} - ${renderNull(
          row.code_postal as string
        )} - ${renderNull(row.ville as string)} - ${renderNull(
          row.pays as string
        )} (${renderNull(row.iso as string)})`,
    },
    {
      title: <div className="form-label">Complément</div>,
      dataIndex: 'complement',
      key: 'complement',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">GLN</div>,
      dataIndex: 'gln',
      key: 'gln',
      width: 110,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Actions</div>,
      key: 'action',
      width: 110,
      className: currentFournisseur.fournisseur
        ? 'block'
        : 'hidden bg-transparent',
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.ADDRESSE}
          handleCheckedRadio={() => handleAttFounisseur(TableType.ADDRESSE, el)}
        />
      ),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: <div className="form-label">Contact</div>,
      dataIndex: 'nom',
      key: 'contact',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Tel</div>,
      dataIndex: 'tel',
      key: 'tel',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Mobile</div>,
      dataIndex: 'mobile',
      key: 'mobile',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Email</div>,
      dataIndex: 'email',
      key: 'email',
      width: 225,
      render: (el) => renderNull(el),
    },
    {
      title: <div className="form-label">Actions</div>,
      key: 'action',
      width: 110,
      className: currentFournisseur.fournisseur
        ? 'block'
        : 'hidden bg-transparent',
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.CONTACT}
          handleCheckedRadio={() => handleAttFounisseur(TableType.CONTACT, el)}
        />
      ),
    },
  ]

  //show alert detail when detail data is not valid
  useEffect(() => {
    if (!isValid_detailData && numberOfSubmit > 0) {
      setIsShowAlertDetail(true)
    }
    if (isValid_detailData && numberOfSubmit > 0) {
      setIsShowAlertDetail(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  useEffect(() => {
    if (!isValid_detailData_mission && numberOfSubmit > 0) {
      setIsShowAlertDetailMission(true)
    }
    if (isValid_detailData_mission && numberOfSubmit > 0) {
      setIsShowAlertDetailMission(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmit])

  useEffect(() => {
    if (numberOfSubmit !== 0) return
    setIsShowAlertDetail(false)
    setIsShowAlertDetailMission(false)
  }, [numberOfSubmit])

  //call api get attendu data after create
  useEffect(() => {
    if (params.id) {
      setLoading(true)

      attenduApi
        .getAttenduById(params.id)
        .then((res) => {
          const values = res.data.entry
          // if 3 warehouse information are different from 3 warehouse chosen from search bar then navigate to dashboard
          if (!checkIfClientWarehouseCompanyCorrespond(values)) {
            return navigate('/tableau-de-bord')
          }
          setAttendu(res.data.entry)
          form.setFieldsValue({
            bl: values.bl,
            number_of_fournisseur: values.number_of_fournisseur,
            client: values.client_code_nom,
            att_commentaire: {
              content: values.att_commentaire?.content || '',
              id: values.att_commentaire || undefined,
            },
            fournisseur: values.att_fournisseur?.code_nom,
            commande: values.commande ? dayjs.unix(values.commande) : null,
            livraison: values.livraison ? dayjs.unix(values.livraison) : null,
            priorite: values.priorite || 5,
            no_commande: values.no_commande || '',
          })

          dispatch(
            setCurrentFournisseurEdit({
              fournisseur_id: values.fournisseur_id,
              ...values.att_fournisseur,
            })
          )

          setCreateAt(
            moment((values.create_at || 0) * 1000).format(t('time-format'))
          )
          setUpdateAt(
            moment((values.update_at || 0) * 1000).format(t('time-format'))
          )

          if (!values.att_fournisseur?.addresse) {
            setDataFournisseurAdresses([])
          } else setDataFournisseurAdresses([values.att_fournisseur])

          if (!values.att_fournisseur?.contact) {
            setDataFournisseurContact([])
          } else
            setDataFournisseurContact([
              {
                nom: values.att_fournisseur.contact,
                ...values.att_fournisseur,
              },
            ])

          setAttFournisseur(values.att_fournisseur || {})

          setDataFile(values.att_file || [])

          setNumberOfPhotos(values.photos || 0)

          //set detail table
          let newData = mapDataAttProduction(values.att_production || [])
          //display sscc from api on detail table
          newData.forEach((data, i) =>
            data.forEach((d) => {
              if (d.name === 'sscc' && values.att_production) {
                d.value = values.att_production[i].sscc_ob?.num
              }
              if (d.name === 'dluo' || d.name === 'lot') {
                if (!d.value) d.disabled = true
              }
            })
          )

          newData = newData.map((line) => {
            line = line.filter((item) => item.name !== 'uuid')
            line.push({ name: 'uuid', value: randomID('detail-line') })
            return line
          })
          setDetailDataFromApi(newData)

          setLoading(false)
        })
        .catch((e) => {
          notification.open({
            className: 'noti noti-error',
            message: (
              <div className="flex items-center">
                <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                Une erreur s'est produite. Merci de réessayer ultérieurement
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, form])

  //if not change radio button in fournisseur addresse and contact table,
  //default checked is first row data => default fournisseur data is first row data
  useEffect(() => {
    if (!currentFournisseur.fournisseur) {
      setAttFournisseur({})
      return
    } else if (currentFournisseur.fournisseur) {
      setDataFournisseurAdresses(
        currentFournisseur.fournisseur?.addresse as Adresses[]
      )
      setDataFournisseurContact(
        currentFournisseur.fournisseur?.contact as Contact[]
      )
      setAttFournisseur({
        id: attendu?.att_fournisseur?.id,
        code_nom:
          currentFournisseur.fournisseur?.code +
          '_' +
          currentFournisseur.fournisseur?.nom,
        addresse: currentFournisseur.fournisseur?.addresse[0].addresse,
        code_postal: currentFournisseur.fournisseur?.addresse[0].code_postal,
        ville: currentFournisseur.fournisseur?.addresse[0].ville,
        pays: currentFournisseur.fournisseur?.addresse[0].pays,
        iso: currentFournisseur.fournisseur?.addresse[0].iso,
        complement: currentFournisseur.fournisseur?.addresse[0].complement,
        gln: currentFournisseur.fournisseur?.addresse[0].gln,
        contact: currentFournisseur.fournisseur?.contact[0].nom,
        tel: currentFournisseur.fournisseur?.contact[0].tel,
        mobile: currentFournisseur.fournisseur?.contact[0].mobile,
        email: currentFournisseur.fournisseur?.contact[0].email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur])

  //set fournisseur code nom after return from form creation
  useEffect(() => {
    if (currentFournisseur.fournisseur)
      form.setFieldValue(
        'fournisseur',
        `${currentFournisseur.fournisseur?.code}_${currentFournisseur.fournisseur?.nom}`
      )
    else if (attendu) {
      form.setFieldValue('fournisseur', attendu.att_fournisseur?.code_nom)
    } else {
      form.setFieldValue('fournisseur', '')
      setDataFournisseurAdresses([])
      setDataFournisseurContact([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isCreateFournisseur])

  useEffect(() => {
    if (
      isCreateFournisseur &&
      currentFournisseur.status === StatusAPI.success &&
      currentFournisseur.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateFournisseur(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFournisseur.status])

  useEffect(() => {
    setNumberOfSubmit(0)
  }, [])

  //if change radio button in fournisseur addresse and contact table,
  //fournisseur data is the corresponding checked row data
  const handleAttFounisseur = (type: string, value: any) => {
    let myAttFournisseur = attFournisseur
    switch (type) {
      case TableType.ADDRESSE:
        myAttFournisseur.addresse = value.addresse
        myAttFournisseur.code_postal = value.code_postal
        myAttFournisseur.ville = value.ville
        myAttFournisseur.pays = value.pays
        myAttFournisseur.iso = value.iso
        myAttFournisseur.gln = value.gln
        myAttFournisseur.complement = value.complement
        break
      case TableType.CONTACT:
        myAttFournisseur.contact = value.nom
        myAttFournisseur.tel = value.tel
        myAttFournisseur.mobile = value.mobile
        myAttFournisseur.email = value.email
        break
      default:
        break
    }
    setAttFournisseur(myAttFournisseur)
  }

  //get fournisseur by code when on change fournisseur
  const onChangeFournisseur = async (newValue: NameAndValue, code: string) => {
    if (newValue.name) {
      dispatch(getFournisseurByCode({ code }))
      try {
        const item = await fournisseurApi.getFournisseurByName({
          code,
        })
        const result = item.data.entry
        dispatch(
          setCurrentFournisseurEdit({
            fournisseur_id: result?.id,
            code_nom: `${result?.code}_${result?.nom}`,
          })
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  //when change number fournisseur, number fournisseur remove red border, remove error text, remove tones in input
  const onChangeNoFournisseur = (newValue: string) => {
    document
      .getElementById('number_of_fournisseur')
      ?.classList.remove('red-border')
    setIsExistNoFournisseur('')
    form.setFieldValue('number_of_fournisseur', removeTones(newValue))
  }

  //when change number, number remove red border, remove error text, remove tones in input
  const onChangeNumber = (newValue: string) => {
    setIsExistAttendu('')
    document
      .getElementById('number_of_fournisseur')
      ?.classList.remove('red-border')
    form.setFieldValue('bl', removeTones(newValue))
  }

  const deleteAttendu = () => {
    setOpenPopupDelete(true)
  }

  function checkIfNeedSyncData() {
    for (let i = 0; i < detailData.length; i++) {
      const ref_change = detailData[i].find(
        (item: any) => item.name === 'ref_change'
      )?.value
      if (Boolean(ref_change)) {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center text-sm mr-8">
              Veuillez mettre à jour toutes les références
            </div>
          ),
          placement: 'topRight',
          duration: 3,
        })
        return true
      }
    }
    return false
  }

  //call when finish form. Popup success if edit success
  const onFinish = (values: any) => {
    if (numberOfPhotos > 20) return
    if (checkIfNeedSyncData()) {
      return
    }
    setNumberOfSubmit(numberOfSubmit + 1)
    let attFile = dataFile.map((el) => ({
      name: el.name,
      path: el.path,
      description: el.description,
      attendu_id: el.attendu_id,
      id: el.id,
    }))

    values.att_commentaire.attendu_id = attendu?.att_commentaire?.attendu_id
    values.att_commentaire.id = attendu?.att_commentaire?.id

    //map 2 way array to 1 way array which sscc is an object

    let mapDetail = mapDataDetail(detailData).map((item) => {
      if (item.sscc_ob) {
        item.sscc_ob.num = item.sscc
        item.sscc_ob.id = item.sscc_id
        item.sscc_ob.att_production_id = item.att_production_id
      }

      delete item.sscc_id
      delete item.att_production_id

      item.status = StatusAttendu.CREATED
      return item
    })
    if (mapDetail.find((item) => !Boolean(item.reference_number)))
      mapDetail = []

    //call api edit attendu
    if (isValid_detailData) {
      setLoading(true)
      const needLinkReferenceList =
        form.getFieldValue('needLinkReferenceList') || []
      let newRefFours: any[] = []
      mapDetail?.forEach((att: ReferenceAttendu) => {
        if (
          !!needLinkReferenceList.find(
            (item: { ref_nom: string; reference_fournisser: string }) =>
              item.ref_nom === att.reference_number &&
              item.reference_fournisser === att.ref_fournisseur_number
          ) &&
          !newRefFours.find(
            (item) =>
              item.ref_nom === att.reference_number &&
              item.reference_id === att.reference_id
          )
        ) {
          newRefFours.push({
            client_code_nom: values.client || '',
            fournisser_id:
              currentFournisseur.fournisseur?.id ||
              attendu?.fournisseur_id ||
              undefined,
            ref_nom: att.reference_number,
            reference_fournisser: att.ref_fournisseur_number,
            reference_id: att.reference_id,
          })
        }
      })

      attenduApi
        .editAttendu({
          id: params.id,
          bl: values.bl,
          create_at: 0,
          update_at: 0,
          commande: values.commande?.unix() || 0,
          livraison: values.livraison?.unix() || 0,
          number_of_fournisseur: values.number_of_fournisseur,
          priorite: values.priorite,
          att_commentaire: values.att_commentaire,
          att_production: mapDetail,
          att_fournisseur: attFournisseur,
          qte_total: 1122,
          sous_qte_total: 1122,
          att_file: attFile.length > 0 ? attFile : undefined,
          new_ref_fours: newRefFours,
          fournisseur_id:
            currentFournisseur.fournisseur?.id ||
            attendu?.fournisseur_id ||
            undefined,
          no_commande: values.no_commande,
          status: StatusAttendu.CREATED,
          photos: numberOfPhotos || 0,
          company_code_nom:
            localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
          warehouse_code_nom:
            localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
          client_code_nom:
            localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
        })
        .then(() => {
          //if edit attendu success: display popup success, close modal, refresh all attendu
          notification.open({
            className: 'noti noti-success',
            message: (
              <div className="flex items-center">
                <img
                  src={SUCCESS}
                  alt="success"
                  width={50}
                  className="mr-2.5"
                />
                Succès: Attendu enregistrée
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
          setLoading(false)
          onCancelModal()
        })
        .catch((error) => {
          setLoading(false)
          //check if number_of_fournisseur is duplicated
          if (error.response) {
            if (error.response.data.error.includes('number_of_fournisseur')) {
              setIsExistNoFournisseur('Numéro déjà existant')
              refToTop.current && refToTop.current.scrollIntoView()
              document
                .getElementById('number_of_fournisseur')
                ?.classList.add('red-border')
            }
          }

          //if edit attendu failed: display popup error, close modal
          else {
            notification.open({
              className: 'noti noti-error',
              message: (
                <div className="flex items-center">
                  <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                  Une erreur s'est produite. Merci de réessayer ultérieurement
                </div>
              ),
              placement: 'topRight',
              closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
              duration: 3,
            })
            onCancelModal()
          }
        })
    }
  }

  //function call when click button Sauvegader but submit failed
  const onFinishFailed = () => {
    setNumberOfSubmit(numberOfSubmit + 1)
  }

  //call when close modal
  const onCancelModal = (data?: { itemPerPage?: number }) => {
    form.resetFields()
    setDataFournisseurAdresses([])
    setDataFournisseurContact([])
    dispatch(clearCurrentFournisseur())
    dispatch(initialAttendu())
    setIsCreateFournisseur(false)
    setIsExistNoFournisseur('')
    setDataFile([])
    setDetailDataFromApi([])
    setIsShowAlertDetail(false)
    setIsEmptyCommande(false)
    setIsEmptyLivraison(false)
    setNumberOfSubmit(0)

    document
      .getElementById('number_of_fournisseur')
      ?.classList.remove('red-border')

    if (data?.itemPerPage === 1 && Number(pageIndex) !== 1) {
      navigate(
        `/gestion-de-stock/attendu?page-index=${
          Number(pageIndex) - 1
        }&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    } else
      navigate(
        `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
  }

  //call when click Supprimer in popup delete
  const onConfirmDelete = (id: string) => {
    const itemPerPage = metadata.count
    attenduApi
      .deleteAttenduById(id)
      .then(() => {
        setOpenPopupDelete(false)
        onCancelModal({ itemPerPage })
      })
      .catch(() => {
        setOpenPopupDelete(false)
        onCancelModal()
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
      })
  }

  //call when close popup delete
  const onClosePopupDelete = () => {
    setOpenPopupDelete(false)
    focusById(attenduKey['supprimer'])
  }

  //call when click Sauvegader in popup import
  const onConfirmImport = (data: any) => {
    setOpenPopupImport(false)
    setLoadingUploadFile(true)

    let formData = new FormData()
    for (let i = 0; i < data.length; i++) {
      formData.append('files', data[i])
    }
    formData.append('att_id', attendu?.id || '')

    //call api upload files
    fileApi
      .uploadMultiFiles(formData)
      .then((res) => {
        setLoadingUploadFile(false)
        setDataFile([...dataFile, ...res.data])
      })

      .catch((e) => {
        setLoadingUploadFile(false)
        alert(e)
      })
  }

  //remove file from UI
  const removeFileItem = (id: string, path: string) => {
    //delete file through api if file not have id
    if (!id) {
      fileApi
        .deleteFile(path)
        .then((res) => {
          let data = dataFile.filter((f) => f.path !== path)
          setDataFile(data)
        })
        .catch((e) => alert(e))
    } else {
      //if file have id, delete directly
      let data = dataFile.filter((f) => f.path !== path)
      setDataFile(data)
    }
  }

  //open new window print file
  const printFile = (pathUrl: string) => {
    if (pathUrl) {
      let path = `${baseURL}/files/attendu/show/${pathUrl}`
      //open new window
      let mywindow = window.open(path, '_blank')
      if (mywindow) {
        //display file in new window
        mywindow.document.write(
          '<html><head><title>' + document.title + '</title>'
        )
        mywindow.document.write('</head><body >')
        if (
          path.toLowerCase().includes('jpg') ||
          path.toLowerCase().includes('png') ||
          path.toLowerCase().includes('jpeg')
        ) {
          mywindow.document.write(`<img src=${path} alt="qa"/>`)
        }
        if (path.toLowerCase().includes('pdf')) {
          mywindow.document.write(`<embed
            type="application/pdf"
            src=${path}
            
            width="100%"
            height="100%" />`)
        }

        mywindow.document.write('</body></html>')

        mywindow.document.close() // necessary for IE >= 10

        mywindow.onload = function () {
          // wait until all resources loaded
          if (path.toLowerCase().includes('pdf')) {
            setTimeout(function () {
              mywindow?.focus() // necessary for IE >= 10
              mywindow?.print() // change window to mywindow
            }, 3000)
          } else {
            mywindow?.focus() // necessary for IE >= 10
            mywindow?.print() // change window to mywindow
            mywindow?.close() // change window to mywindow
          }
        }
      }
    }
  }

  //handle data from detail data tale
  const onChangeDetailData = (data: DataTableParam[][]) => {
    setDetailData(
      data.map((item) => {
        const attendu_id_index = item.findIndex(
          (item) => item.name === 'attendu_id'
        )

        if (attendu_id_index === -1)
          item.push({ name: 'attendu_id', value: params.id })
        else item[attendu_id_index].value = params.id

        return item
      })
    )
  }

  //check valid data from detail table, if detail data is valid => button Sauvagader starts working
  const onCheckValidDetailData = (isValid: boolean) => {
    set_isValid_detailData(isValid)
  }

  const sendOnMission = () => {
    if (numberOfPhotos > 20) return
    if (checkIfNeedSyncData()) {
      return
    }
    setNumberOfSubmit(numberOfSubmit + 1)
    if (name === ModalName.ATTENDU && type === ModalType.CREATION) {
      dispatch(openModal(false))
    }

    if (!attendu) return
    if (!form.getFieldValue('commande') || !form.getFieldValue('livraison')) {
      refToTop.current && refToTop.current.scrollIntoView()
      if (!form.getFieldValue('commande')) setIsEmptyCommande(true)
      else setIsEmptyCommande(false)
      if (!form.getFieldValue('livraison')) setIsEmptyLivraison(true)
      else setIsEmptyLivraison(false)
      return
    }

    let attFile = dataFile.map((el) => ({
      name: el.name,
      path: el.path,
      description: el.description,
      attendu_id: el.attendu_id,
      id: el.id,
    }))

    form.getFieldValue('att_commentaire').attendu_id =
      attendu?.att_commentaire?.attendu_id
    form.getFieldValue('att_commentaire').id = attendu.att_commentaire?.id

    //map 2 way array to 1 way array which sscc is an object
    let mapDetail = mapDataDetail(detailData).map((item) => {
      if (item.sscc_ob) {
        item.sscc_ob.num = item.sscc
        item.sscc_ob.id = item.sscc_id
        item.sscc_ob.att_production_id = item.att_production_id
      }
      delete item.sscc_id
      delete item.att_production_id

      item.mouvement = 'EX'
      item.type = '10'
      item.eta = EtaAttendu.UNASSIGNED
      item.status = StatusAttendu.UNASSIGNED
      return item
    })
    if (mapDetail.find((item) => !Boolean(item.reference_number)))
      mapDetail = []

    //call api edit attendu
    if (isValid_detailData && isValid_detailData_mission) {
      setLoading(true)
      const needLinkReferenceList =
        form.getFieldValue('needLinkReferenceList') || []
      let newRefFours: any[] = []
      mapDetail?.forEach((att: ReferenceAttendu) => {
        if (
          !!needLinkReferenceList.find(
            (item: { ref_nom: string; reference_fournisser: string }) =>
              item.ref_nom === att.reference_number &&
              item.reference_fournisser === att.ref_fournisseur_number
          )
        ) {
          newRefFours.push({
            client_code_nom: form.getFieldValue('client') || '',
            fournisser_id:
              currentFournisseur?.fournisseur?.id || currentFournisseurEdit?.id,
            ref_nom: att.reference_number,
            reference_fournisser: att.ref_fournisseur_number,
          })
        }
      })

      attenduApi
        .editAttendu({
          id: params.id,
          bl: form.getFieldValue('bl'),
          create_at: 0,
          update_at: 0,
          commande: form.getFieldValue('commande').unix() || 0,
          livraison: form.getFieldValue('livraison').unix() || 0,

          number_of_fournisseur: form.getFieldValue('number_of_fournisseur'),
          priorite: form.getFieldValue('priorite'),
          att_commentaire: {
            content: form.getFieldValue('att_commentaire').content || '',
            id: form.getFieldValue('att_commentaire').id || '',
            attendu_id: form.getFieldValue('att_commentaire').attendu_id || '',
          },
          att_production: mapDetail,
          att_fournisseur: attFournisseur,
          qte_total: 1122,
          sous_qte_total: 1122,
          att_file: attFile.length > 0 ? attFile : undefined,
          new_ref_fours: newRefFours,
          fournisseur_id: currentFournisseur.fournisseur?.id || undefined,
          no_commande: form.getFieldValue('no_commande'),
          status: StatusAttendu.UNASSIGNED,
          photos: numberOfPhotos || 0,
          company_code_nom:
            localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
          warehouse_code_nom:
            localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
          client_code_nom:
            localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
          modification_by: localStorage.getItem(Define.USERNAME) || '',
          modificationer_id: localStorage.getItem(Define.USER_ID) || '',
          mouvement: 'EX',
          type: '10',
          eta: EtaAttendu.UNASSIGNED,
        })
        .then(() => {
          navigate(
            `/gestion-de-stock/attendu/attendu-on-mission/${
              attendu?.id
            }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`,
            { replace: true }
          )

          //setOpenAttenduEdit(false)
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    // if (detailData.length === 1) {
    for (let i = 0; i < detailData.length; i++) {
      const currentLine = detailData[i]
      const ref = currentLine.find((item) => item.name === 'reference')
      if (ref && !Boolean(ref.value)) {
        setIsValid_detailData_mission(false)
        return
      }
    }

    setIsValid_detailData_mission(true)
    //}
  }, [detailData])

  const onChangeInit = (isInit: boolean) => {
    setIsDetailInit(isInit)
  }

  const onChangePhoto = (photo: number) => {
    setNumberOfPhotos(photo)
  }

  useEffect(() => {
    focusById(attenduKey['Commande'])
  }, [])

  return (
    <Form
      id="attendu-edit"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Modal
        prefixCls="modal-new-stock"
        centered
        open={true}
        onCancel={() => onCancelModal()}
        width={'90%'}
        className="border-solid border-secondary rounded-xl"
        footer={null}
        destroyOnClose={true}
      >
        {loading && <Loading />}
        <>
          <div
            className="flex items-baseline border-none pb-2 mb-2"
            style={{ borderBottom: '1px solid #B7B7B7' }}
          >
            <div className="flex items-center">
              <span className="modal-title-heading-1 mr-3">Attendu</span>
              <span className="text-[#20458F] text-2xl">
                (n° {form.getFieldValue('bl')})
              </span>
            </div>
          </div>
          <div className="flex justify-between italic text-[#808080]">
            <div className="text-[#848484]">Création : {createAt}</div>
            <div className="text-[#848484]">Modification : {updateAt}</div>
          </div>
          <div className="h-2"></div>
          <CustomPopup
            title="Confirmation de la suppression?"
            type={popupType.DELETE}
            isOpen={openPopupDelete}
            onConfirm={() => onConfirmDelete(params.id || '')}
            onClose={onClosePopupDelete}
          >
            <p className="text-lg text-[#505050] my-10">
              Êtes-vous sûr de vouloir supprimer cet attendu?
            </p>
          </CustomPopup>

          <div className="overflow-y-auto main-content-2">
            <SectionLayout title="Informations">
              <div
                className="flex justify-between infomation-section"
                ref={refToTop}
              >
                <div>
                  <Form.Item
                    label="N° de BL"
                    name="bl"
                    rules={[{ required: true, message: '' }]}
                    colon={false}
                    validateStatus={isExistAttendu ? 'error' : undefined}
                    help={isExistAttendu}
                  >
                    <Input
                      style={{ width: '100%' }}
                      maxLength={20}
                      onChange={(e) => onChangeNumber(e.target.value)}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    label="N° Fournisseur"
                    name="number_of_fournisseur"
                    colon={false}
                    validateStatus={isExistNoFournisseur ? 'error' : undefined}
                    help={isExistNoFournisseur}
                  >
                    <Input
                      id={attenduKey['N-Fournisseur']}
                      data-next-id={attenduKey['N-Commande']}
                      data-previous-id={attenduKey['Priorité']}
                      style={{ width: '100%' }}
                      maxLength={20}
                      onChange={(e) => {
                        onChangeNoFournisseur(e.target.value)
                      }}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    label="Commande"
                    name="commande"
                    colon={false}
                    validateStatus={isEmptyCommande ? 'error' : undefined}
                    help={isEmptyCommande}
                  >
                    <CDatePicker
                      id={attenduKey['Commande']}
                      data-next-id={`${attenduKey['Livraison']}, ${attenduKey['Commentaire']}`}
                      data-previous-id={attenduKey['n-input']}
                      placement="topLeft"
                      onChange={() => setIsEmptyCommande(false)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="N° Commande"
                    name="no_commande"
                    colon={false}
                  >
                    <Input
                      id={attenduKey['N-Commande']}
                      data-next-id={`
                        ${attenduKey['Code-Nom-Fournisseur']}, 
                        ${FormCreationType.FOURNISSEUR}-nom, 
                        ${attenduKey['Filtre-fournisseur']},
                        ${attenduKey['supprimer']}
                      `}
                      data-previous-id={attenduKey['N-Fournisseur']}
                      style={{ width: '100%' }}
                      maxLength={20}
                      onChange={(e) => {
                        form.setFieldValue(
                          'no_commande',
                          removeTones(e.target.value)
                        )
                      }}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Livraison"
                    name="livraison"
                    colon={false}
                    validateStatus={isEmptyLivraison ? 'error' : undefined}
                    help={isEmptyLivraison}
                  >
                    <CDatePicker
                      id={attenduKey['Livraison']}
                      data-next-id={attenduKey['Priorité']}
                      data-previous-id={attenduKey['Commande']}
                      disabledDate={(current) => {
                        return moment().add(-1, 'days') >= current
                      }}
                      placement="topLeft"
                      onChange={() => setIsEmptyLivraison(false)}
                    />
                  </Form.Item>
                  <Form.Item label="Client" name="client" colon={false}>
                    <Input disabled style={{ background: '#F2F2F2' }} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Priorité"
                    name="priorite"
                    colon={false}
                    className="priorite-item"
                  >
                    <Select
                      id={attenduKey['Priorité']}
                      aria-roledescription={attenduKey['N-Fournisseur']}
                      aria-keyshortcuts={attenduKey['Livraison']}
                      style={{ width: 87, marginLeft: 60 }}
                    >
                      {[5, 4, 3, 2, 1].map((i) => (
                        <Select.Option key={i} value={i}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </SectionLayout>

            {isCreateFournisseur ? (
              <div>
                <FormCreation
                  type={FormCreationType.FOURNISSEUR}
                  isCreateForm={isCreateFournisseur}
                  onCloseForm={() => setIsCreateFournisseur(false)}
                />
              </div>
            ) : (
              <SectionLayout
                title="Fournisseur"
                action={
                  <Action
                    isShowIconPlus={!detail?.validAtLeastOneLine}
                    onClickPlusBtn={() => setIsCreateFournisseur(true)}
                  />
                }
              >
                <div style={{ overflow: 'auto' }}>
                  <Form.Item
                    label="Code & Nom Fournisseur"
                    colon={false}
                    name="fournisseur"
                  >
                    <CustomSearch
                      id={attenduKey['Code-Nom-Fournisseur']}
                      previousId={attenduKey['N-Commande']}
                      nextId={attenduKey['Filtre-fournisseur']}
                      disabled={detail?.validAtLeastOneLine}
                      onChange={onChangeFournisseur}
                      functionSearch={
                        fournisseurApi.searchFournisseurByNameOrCode
                      }
                      className="attendu-custom-search"
                    />
                  </Form.Item>
                  <div className="flex justify-between">
                    {(currentFournisseur.fournisseur ||
                      (dataFournisseurAdresses.length >= 1 &&
                        dataFournisseurContact.length >= 1 &&
                        !currentFournisseur.fournisseur)) && (
                      <>
                        <Table
                          rowKey="id"
                          bordered
                          columns={columnsAdresses}
                          loading={
                            currentFournisseur.status === StatusAPI.calling
                          }
                          dataSource={dataFournisseurAdresses}
                          className="custom-common-modal mr-10"
                          pagination={false}
                        />
                        <Table
                          rowKey="id"
                          bordered
                          columns={columnsContact}
                          loading={
                            currentFournisseur.status === StatusAPI.calling
                          }
                          dataSource={dataFournisseurContact}
                          className="custom-common-modal"
                          pagination={false}
                        />
                      </>
                    )}
                  </div>
                </div>
              </SectionLayout>
            )}
            <ModalDetailComponent
              mode={MODE.EDIT}
              client={form.getFieldValue('client')}
              initData={
                !currentFournisseurEdit?.fournisseur_id
                  ? undefined
                  : detailDataFromApi
              }
              onChangeData={onChangeDetailData}
              onCheckValid={onCheckValidDetailData}
              alert={
                isShowAlertDetail ? (
                  isDetailInit ? (
                    alertNode('Veuillez ajouter une référence')
                  ) : (
                    alertNode('Veuillez valider toutes ces lignes suivantes')
                  )
                ) : isShowAlertDetailMission ? (
                  isDetailInit ? (
                    alertNode('Veuillez ajouter une référence')
                  ) : (
                    alertNode('Veuillez valider toutes ces lignes suivantes')
                  )
                ) : (
                  <></>
                )
              }
              onChangeInit={onChangeInit}
              //detailError={errorDetail}
              onChangePhoto={onChangePhoto}
              valuePhoto={numberOfPhotos}
            />
            <SectionLayout title="Commentaire">
              <Form.Item name={['att_commentaire', 'content']}>
                <TextArea
                  id={attenduKey['Commentaire']}
                  data-next-id={[
                    attenduKey['supprimer'],
                    attenduKey['envoyer'],
                    attenduKey['Sauvegarder'],
                  ]}
                  style={{
                    height: 100,
                    width: 390,
                    resize: 'none',
                    marginLeft: '7%',
                  }}
                  showCount
                  maxLength={200}
                />
              </Form.Item>
            </SectionLayout>

            <SectionLayout
              title="Documents"
              action={
                <Action onClickPlusBtn={() => setOpenPopupImport(true)} />
              }
            >
              <Documents
                isOpen={openPopupImport}
                onClose={() => setOpenPopupImport(false)}
                onConfirm={onConfirmImport}
              />
              <table className="table-document">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Voir</th>
                    <th>Télécharger</th>
                    <th>Imprimer</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loadingUploadFile ? (
                  <Loading />
                ) : (
                  <tbody>
                    {dataFile.map((el, index) => (
                      <tr key={index}>
                        <td>{el.name}</td>
                        <td>
                          <a
                            className="view"
                            href={`${baseURL}/files/attendu/show/${el.path}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            a
                          </a>
                        </td>
                        <td>
                          <a
                            className="export"
                            download
                            href={`${baseURL}/files/attendu/${el.path}`}
                          >
                            a
                          </a>
                        </td>
                        <td>
                          <i
                            className="printer"
                            onClick={() => printFile(el.path)}
                          ></i>
                        </td>
                        <td>
                          <Popconfirm
                            title=""
                            description="Êtes-vous sûr de vouloir supprimer cet élément?"
                            onConfirm={() =>
                              removeFileItem(el.id || '', el.path)
                            }
                            okText="Supprimer"
                            cancelText="Annuler"
                            icon={null}
                          >
                            <i className="delete"></i>
                          </Popconfirm>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </SectionLayout>
          </div>
          <div className="flex justify-between items-center footer-content">
            <div>
              <button
                id={attenduKey['supprimer']}
                disabled={loading}
                onClick={deleteAttendu}
                className={clsx(
                  'supprimer border-red border-solid bg-white hover:text-red text-red text-lg font-semibold pl-12 pr-5 py-1 rounded-lg mr-4 relative',
                  !loading ? 'cursor-pointer' : 'cursor-not-allowed'
                )}
              >
                Supprimer
              </button>
              <button
                id={attenduKey['envoyer']}
                disabled={loading}
                className={clsx(
                  'envoyer border-[#01E37A] border-solid bg-white text-[#01E37A] hover:text-[#01E37A] text-lg font-semibold  pl-12 pr-5 py-1 rounded-lg relative',
                  !loading ? 'cursor-pointer' : 'cursor-not-allowed'
                )}
                onClick={sendOnMission}
              >
                Envoyer en mission
              </button>
            </div>

            <Button
              id={attenduKey['Sauvegarder']}
              data-next-id={attenduKey['Commande']}
              disabled={loading}
              prefixCls=" text-btn-submit-last-modal"
              className={clsx(
                'bg-[#01e37a] px-5 py-2 border-none rounded-lg  hover:text-white',
                !loading ? 'cursor-pointer' : 'cursor-not-allowed'
              )}
              key="submit"
              htmlType="submit"
              form="attendu-edit"
            >
              Sauvegarder
            </Button>
          </div>
        </>
      </Modal>
    </Form>
  )
}

export default AttenduEdit

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)
