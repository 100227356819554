import moment from 'moment'
import { DataTableParam } from '../../features/stock/stockscreen/component/model'
import { ReferenceAttendu } from '../../models'
import { t } from 'i18next'

export const mapDataAttProduction = (data: ReferenceAttendu[]) => {
  let att_production_data: ReferenceAttendu[] = data.map(
    ({
      ref_fournisseur_number: ref_fournisseur,
      reference_number: reference,
      quantite: qte,
      conditionement: cdn,
      conditionement_id: cdn_id,
      ...rest
    }) => ({
      ref_fournisseur,
      reference,
      qte,
      cdn,
      conditionement: cdn,
      conditionement_id: cdn_id,
      ...rest,
    })
  )

  let mapData: DataTableParam[][] = []
  let demo = []

  for (let i = 0; i < att_production_data.length; i++) {
    let cdn = '',
      volume_qte = '',
      poids_qte = '',
      volume_sousqte = '',
      poids_sousqte = '',
      conditionement_id = ''

    for (let j = 0; j < Object.keys(att_production_data[i]).length; j++) {
      let name = Object.keys(att_production_data[i])[j]
      let value = Object.values(att_production_data[i])[j]
        ? Object.values(att_production_data[i])[j]
        : ''

      let item: { name: string; value: string; disabled?: boolean } = {
        name: Object.keys(att_production_data[i])[j],
        value: Object.values(att_production_data[i])[j]
          ? Object.values(att_production_data[i])[j].toString()
          : '',
      }
      item.name = name
      if (name !== 'sscc_ob') {
        item.value = value.toString()
      } else {
        demo.push({ name: 'sscc_id', value: value.id })
        demo.push({ name: 'att_production_id', value: value.att_production_id })
      }
      if (
        [
          'libelle',
          'sous_qte',
          'volume_qte',
          'poids_qte',
          'volume_sousqte',
          'poids_sousqte',
          'conditionement_id',
        ].includes(item.name)
      ) {
        item.disabled = true
      }

      // if (item.name === 'cdn') cdn = item.value
      if (item.name === 'poids_sousqte') poids_sousqte = item.value
      if (item.name === 'poids_qte') poids_qte = item.value
      if (item.name === 'volume_sousqte') volume_sousqte = item.value
      if (item.name === 'volume_qte') volume_qte = item.value
      if (item.name === 'conditionement_id')
        cdn = conditionement_id = item.value

      demo.push(item)
    }

    if (!demo.find((item) => item.name === 'qte')?.value) {
      pushFieldToArray('qte', demo, '1')
    }

    if (!demo.find((item) => item.name === 'lot')?.value) {
      pushFieldToArray('lot', demo, '')
    } else {
      const value = demo.find((item) => item.name === 'lot')?.value
      pushFieldToArray('lot', demo, value, false, '', true, true)
    }

    if (!demo.find((item) => item.name === 'sous_qte')?.value) {
      pushFieldToArray('sous_qte', demo, '-', true)
    } else {
      const value = demo.find((item) => item.name === 'sous_qte')?.value
      pushFieldToArray('sous_qte', demo, value, true)
    }

    if (
      !demo.find((item) => item.name === 'dluo')?.value ||
      Number(demo.find((item) => item.name === 'dluo')?.value) < 0
    ) {
      pushFieldToArray('dluo', demo, '')
    } else {
      const value = demo.find((item) => item.name === 'dluo')?.value
      pushFieldToArray('dluo', demo, value, false, '', true, true)
    }

    popFieldFromArray('create_at', demo)
    popFieldFromArray('update_at', demo)
    pushFieldToArray('sscc', demo)
    pushFieldToArray('volume_qte', demo, volume_qte, true)
    pushFieldToArray('poids_qte', demo, poids_qte, true)
    pushFieldToArray('volume_sousqte', demo, volume_sousqte, true)
    pushFieldToArray('poids_sousqte', demo, poids_sousqte, true)
    pushFieldToArray('conditionement_id', demo, conditionement_id, true)

    formatDLUO('dluo', demo)
    const cdnIndex = demo.findIndex((item) => item.name === 'cdn')
    const conditionement_id_index = demo.findIndex(
      (item) => item.name === 'conditionement_id'
    )
    if (cdnIndex !== -1 && conditionement_id_index !== -1) {
      demo[cdnIndex].value = demo[conditionement_id_index].value
    }

    if (
      att_production_data[i].conditionnement?.qte === 1 &&
      att_production_data[i].conditionnement?.niveau === 1
    ) {
      const sousQteIndex = demo.findIndex((item) => item.name === 'sous_qte')
      if (sousQteIndex !== -1) demo[sousQteIndex].value = '-'
    }

    mapData.push(demo)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cdn = ''
    demo = []
  }

  return mapData
}

const pushFieldToArray = (
  field: string,
  array: {
    name: string
    value: string
    disabled?: boolean
    label?: string
    requiredVariant?: boolean
    requiredMessage?: string
    required?: boolean
  }[],
  defaultValue?: string,
  isDisabled?: boolean,
  label?: string,
  requiredVariant?: boolean,
  required?: boolean
) => {
  const fieldIndex = array.findIndex((item) => item.name === field)
  if (fieldIndex === -1) {
    array.push({
      name: field,
      value: defaultValue || '',
      disabled: isDisabled || false,
      label: label || '',
      requiredVariant: requiredVariant || false,
      requiredMessage: ' ',
      required: requiredVariant || false,
    })
    return
  }
  array[fieldIndex].value = defaultValue || ''
  array[fieldIndex].disabled = isDisabled || false
  array[fieldIndex].required = required || requiredVariant || false
  array[fieldIndex].requiredVariant = requiredVariant || false
  array[fieldIndex].requiredMessage = ' '
}

const popFieldFromArray = (
  field: string,
  array: { name: string; value: string }[]
) => {
  const fieldIndex = array.findIndex((item: any) => item.name === field)

  array.splice(fieldIndex, 1)
}

const formatDLUO = (
  field: string,
  array: { name: string; value: string }[]
) => {
  const dateFormat = t('date-format')
  const findIndex = array.findIndex((item) => item.name === field)
  let arr = array
  if (arr[findIndex]) {
    arr[findIndex].value = array[findIndex]?.value
      ? moment(Number(array[findIndex].value) * 1000).format(dateFormat)
      : ''
  }

  array = arr
  return array
}
